<template>
  <div class="mine container">
      <side class="side"></side>
      <div class="rightMain">
            <router-view/>
      </div>
      
  </div>
</template>

<script>
import Side from './components/Side'
export default {
  name: 'Mine',
  components:{Side},  
  data () {
    return {
       
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.mine.container{
    background: #fff;
    box-shadow: 0px 6px 16px 0px rgba(0, 109, 117, 0.06);
    border-radius: 8px;
    margin: 16px auto;
    min-height: 670px;
    padding-right: 0;
  }
.mine{
    position: relative;
    padding-left: 192px;
    .side{
        width:192px;
        position: absolute;
        left: 0;
        top:0;
        bottom: 0;
    }
}

@media screen and (max-width: 991px){
  .mine .side{position: inherit;width: 100%;}
  .mine.container{padding-left: 0;}
}
</style>
