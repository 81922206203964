    <!-- 个人信息侧边栏 -->
<template>
  <div class="sidecontainer">
    <div class="sideManu-name">
      个人中心
    </div>
    <ul class="sideManue">
      <li
        v-for="(item,index) in tabList"
        :key="item.key"
        :class="{'active':tabIdx==item.key}"
         @click="goto(item,index)"
      >
        <i class="active_line"></i>
        <span class="icon iconfont" v-html="item.iconleft"></span>
        <div>{{item.label}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'side',

  data () {
    return {
      tabIdx: 'BaseInfo',
      tabList: [
        {
          iconleft:'&#xe637;',
          label: '个人信息',
          router: '/Mine/BaseInfo',
          key: 'BaseInfo'
        },
        {
          iconleft:'&#xe655;',
          label: '实名认证',
          router: '/Mine/RealName',
          key: 'RealName'
        },
        // {
        //   iconleft:'&#xe659;',
        //   label: '著作权人管理',
        //   router: '/Mine/Obligee',
        //   key: 'Obligee'
        // },
        // {
        //   iconleft:'&#xe63f;',
        //   // label: '钱包',
        //   label: '账单明细',
        //   router: '/Mine/MoneyBag',
        //   key: 'MoneyBag'
        // },
        // {
        //   iconleft:'&#xe644;',
        //   label: 'VIP权益',
        //   router: '/Mine/Vip',
        //   key: 'MineVip'
        // },
        {
          iconleft:'&#xe64c;',
          label: '我的存证',
          router: '/Mine/ExistingEvidence',
          key: 'MineExistingEvidence'
        },
        {
          iconleft:'&#xe737;',
          label: '我的查重',
          router: '/Mine/MyCheck',
          key: 'MyCheck'
        },
        {
          iconleft:'&#xe605;',
          label: '我的DCI',
          router: '/Mine/MyDCI',
          key: 'MyDCI'
        },
        {
          iconleft:'&#xe64e;',
          label: '我的登记',
          router: '/Mine/MyRegister',
          key: 'MyRegister'
        },
        {
          iconleft:'&#xe67c;',
          label: '我的评价',
          router: '/Mine/MyEvaluate',
          key: 'MyEvaluate'
        },
        //  {
        //   iconleft:'&#xe64b;',
        //   label: '我的监测',
        //   router: '/Mine/Monitor',
        //   key: 'MineMonitor'
        // },
        //  {
        //   iconleft:'&#xe645;',
        //   label: '我的取证',
        //   router: '/Mine/ObtainEvidence',
        //   key: 'MineObtainEvidence'
        // },
        // {
        //   iconleft:'&#xe638;',
        //   label: '合约管理',
        //   router: '/Mine/OnSale',
        //   key: 'MineOnSale'
         
        // },
        {
          iconleft:'&#xe649;',
          label: '消息通知',
          router: '/Mine/Notice',
          key: 'Notice'
        },
        
      ]
    }
  },
  watch:{
    $route(to,from){
      console.log(to.name)
      this.tabIdx=to.name
      if(to.name == 'DCIDetail'){
        this.tabIdx = 'MyDCI'
      }
      if(to.name == 'EvaluateDetail'){
        this.tabIdx = 'MyEvaluate'
      }
    }
},
  mounted () {
    // console.log( this.$route.name)
    this.tabIdx = this.$route.name
    if(this.$route.name == 'DCIDetail'){
        this.tabIdx = 'MyDCI'
      }
    if(this.$route.name == 'EvaluateDetail'){
      this.tabIdx = 'MyEvaluate'
    }
  },
  methods: {
    goto (item, index) {
      this.$router.push({
        path: item.router,
        query: {}
      });
      this.tabIdx = item.key
      if(item.key == 'DCIDetail'){
        this.tabIdx = 'MyDCI'
      }
      if(item.key == 'EvaluateDetail'){
        this.tabIdx = 'MyEvaluate'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.sidecontainer{
  width: 192px;
  // height: 670px;
  padding-bottom: 16px;
  height: 100%;
  background: #F2F8FF;
}
.sideManu-name{
  font-size: 18px;
  font-weight: 500;
  height: 88px;
  line-height: 88px;
  padding-left: 24px;
}
.sideManue {
  li{
    padding:14px 0 14px 54px;
    line-height: 20px;
    position: relative;
    .icon{
    font-size: 22px;
    position: absolute;
    left: 24px;
    top: 14px;
    opacity: 0.8;
    }
    cursor: pointer;
  }
  li.active,li:hover {
    color: #007DFF;
    background: #ffffff;
    .active_line{
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 2px;
      height: 40px;
      background: #007DFF;
    }
    .icon{
      opacity: 1;
    }
  }
}
</style>
